import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Error from "../components/modules/404"

export default () => {
  const robots = {
    name: `robots`,
    content: `noindex, nofollow`,
  }
  return (
    <Layout noFooter highContrast>
      <SEO title="Error: 404" meta={[robots]} />
      <Error />
    </Layout>
  )
}
