/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import GlobalStyles from "./modules/global-styles"
import Footer from "../components/partials/footer"
import Header from "../components/partials/header"
import "../styles/global.css"
import "../styles/layout.css"

const Layout = ({ children, highContrast, inner, noFooter, spanish }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
      </Helmet>
      <GlobalStyles />
      <Header highContrast={highContrast} spanish={spanish} />
      <main>{children}</main>
      {!noFooter && <Footer spanish={spanish} inner={inner} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
