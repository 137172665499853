import React from "react"
import tw, { styled, css } from "twin.macro"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Button from "./button"
import Copyright from "./footer-copyright"

const Wrapper = styled.div(() => [
  tw`relative flex flex-col items-center justify-center min-h-screen bg-marrone-black px-4`,
  css`
    .gatsby-image-wrapper {
      ${tw`mb-12`}
      width: 950px;
      max-width: 95%;
    }

    @media (max-width: 640px) {
      br {
        ${tw`hidden`}
      }
    }
  `,
])

const Super = tw.p`text-lg text-center font-mont font-bold text-marrone-primary mb-6`
const Content = tw(Super)`text-xl text-white -mt-4 mb-12`

export default () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          innerPageSettings {
            errorPage {
              splashImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 950) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const splash =
        data.wp.innerPageSettings.errorPage.splashImage.localFile
          .childImageSharp.fluid
      return (
        <Wrapper>
          <Img fluid={splash} />
          <Super>Sorry!</Super>
          <Content>
            It looks like something went wrong on our end.
            <br />
            &nbsp;Head back to our homepage.
          </Content>
          <Button to="/" className="solid-button">
            go back home
          </Button>
          <Copyright />
        </Wrapper>
      )
    }}
  />
)
